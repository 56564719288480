<template>
    <!-- begin::kb-main -->
    <LxpMobileHeader :show-custom-title="true" :show-back="true" title="신입행원 온보딩 항목상세">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>         
        </div>
      </template>
    </LxpMobileHeader>
  
    <main class="kb-main" id="kb-reinstatement-support">
      <!-- main-content -->
      <div class="main-content main-component">
        <div v-if="items.length> 0" class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">              
              <div class="list-content">
                <div class="list-content-title">                  
                   <h3 class="title">{{ item.clssNm }}</h3>                  
                   <h3 class="title"> 점수 : {{ item.score }} 점</h3> 
                   <h3 class="title"> 건수 : {{ item.cnt }} 건</h3> 
   
                </div>

              </div>            
            </li>
          </ul>
        </div>
        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
            <p class="text">검색결과가 없습니다.</p>
          </div>
        </div>       
      </div>
      <!-- //main-content -->
    </main>    
  </template>
  
<script setup>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {useStore} from 'vuex';
import {ref,onMounted } from 'vue';
import {useRoute,useRouter} from 'vue-router';
import {ACT_GET_TOT_LRNER_DTL_SCORE} from '@/store/modules/newzonboarding/newz';
import {getItems,lengthCheck} from "@/assets/js/util";  
const items  = ref([]);
const store = useStore();
const route = useRoute();
const router = useRouter();

const getTotLrnerDtlScore = () => {

    store.dispatch(`newz/${ACT_GET_TOT_LRNER_DTL_SCORE}`, {
        newzClssCd : route.params.newzClssCd,
        distCrseSn : route.params.distCrseSn
    }).then(res => {
        if (lengthCheck(res)) {        
           
         items.value = getItems(res);
          console.log(items.value.length);
        } else {
        items.value = [];
        }
    }).catch(e => {
        items.value = [];
        console.error(e);
    })
  }

const goBack = () => {
    router.go(-1);
 }

onMounted(() => {  
  getTotLrnerDtlScore()
});
  </script>